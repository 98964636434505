<template>
  <ProductForm @saveDetail="saveProduct" :productDetail="productDetail" :update="false" />
</template>

<script>
import ProductForm from "./ProductForm.vue";
import { mapActions } from "vuex";

export default {
  name: "AddProduct",
  components: {
    ProductForm,
  },
  data: () => ({
    productDetail: {
      name: "",
      unit: "",
      price: 0,
      sku: null,
      applicableAreas: [],
      description: ""
    },
  }),
  methods: {
    ...mapActions("product", ["saveProductData"]),
    ...mapActions("ecommerce",["algoliaSync"]),

    saveProduct(data, selectedTreatmentType, treatmentTypesOption) {
      this.$vs.loading();
      let self = this;
      data.treatmentType = selectedTreatmentType;
      this.saveProductData(data)
        .then((res) => {
          self.algoliaSync()
          self.$vs.notify({
            title: "Product Add",
            text: "Product Detail added successfully",
            color: "success",
          });
          self.$vs.loading.close();
          this.$router.push({ name: "ProductManagement" });
        })
        .catch((err) => {
          self.$vs.notify({
            title: "Error",
            text: err.data.message,
            color: "danger",
          });
          self.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped></style>
